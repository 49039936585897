<template>
  <div class="produit_show">
    <svg @click="$router.go('-1')" class="close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>
    <div class="bg"></div>
    <div class="grp_page">
      <div class="img" v-if="produit && produit.img_key">
<!--        <div class="bg_img"></div>-->
<!--        <el-image class="img_load" :src="$static_patch + produit.img" fit="contain" @load="load = true"></el-image>-->
        <img :src="$img_path + produit.img_key" alt="">
  <!--      <img src="https://dummyimage.com/500x500/eee/aaa" alt="">-->
      </div>
  <!--    <div class="line_price">-->
  <!--      <div class="prix">{{ data.price }}€</div>-->
  <!--    </div>-->
      <div class="right">
        <div class="bloc_infos">
        <div class="line_drag"></div>
          
          <div class="pad">
            
            <div class="title anim">{{ produit.name }}</div>
            
  <!--          <div class="note_line anim">-->
  <!--            <el-rate v-model="note" disabled></el-rate>-->
  <!--            <span>14 évaluations</span>-->
  <!--          </div>-->
            
            <div v-if="produit.can_buy_nb < 5" class="tag alertstock anim">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 24 24"><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
              {{ produit.can_buy_nb }} articles disponibles
            </div>
            <div v-else class="tag enstock anim">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="10" height="10" viewBox="0 0 24 24"><path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
              Disponible
            </div>
            
            
            <div class="description anim">{{ produit.description }}</div>

          </div>
          
          
          
          
          
          
          
          <div v-if="options" id="options">
            <div class="produit_select anim" v-for="(n, k) in options" :key="k">
              <div class="titre">
                <div class="t">
                  {{ n.type }}
                  <span v-if="n.conditions[0] && n.conditions[0] === 'obligatoire' && n.conditions[2] && n.conditions[2] === 'set'">Obligatoire • Choisissez en {{ n.conditions_nb }}</span>
                  <span v-else-if="n.conditions[0] && n.conditions[0] === 'obligatoire'">Obligatoire <span v-if="n.conditions[2] && n.conditions[2] === 'limites'">• {{ n.conditions_nb }} maximum</span></span>
                  <span v-if="n.conditions[0] && n.conditions[0] === 'not' && n.conditions[2] && n.conditions[2] === 'limites'">{{ n.conditions_nb }} maximum</span>
                </div>
<!--                <img :src="'/img/ico/food/' + n.type + '.png'" alt="">-->
              </div>
              
              <el-checkbox-group v-if="n.conditions[1]" v-model="options_select[n.id]" :max="n.conditions_nb" @change="options_check">
                <div class="line" v-for="(p, k1) in n.data" :key="k1" :data-details="p.details ? true : false">
                  <el-checkbox :label="p">
                    <div class="l">{{ p.name }}
                      <span class="details">{{ p.details }}</span>
                    </div>
                    <div class="r">
                      <span class="prix" v-if="p.price"><div class="prix">+{{ p.price | formatNumberdecimal }}€</div></span>
                    </div>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              
                <div class="line" v-else v-for="(p, k1) in n.data" :key="k1">
                  <el-radio :label="p" v-model="options_select[n.id]" @change="options_check" :data-details="p.details ? true : false">
                    <div class="l">{{ p.name }}
                      <span class="details">{{ p.details }}</span>
                    </div>
                    <div class="r">
                      <span class="prix" v-if="p.price"><div class="prix">+{{ p.price | formatNumberdecimal }}€</div></span>
                    </div>
                  </el-radio>
                </div>
            </div>
          </div>
          
          
          
          
          
          
          


        </div>
        
        <div class="bloc_price" v-if="load === true">
          
          <div class="discount" v-if="produit.reduc > 0">
            <div class="percent">
              -{{ produit.reduc }}%
            </div>
            <div class="price">
              <div class="prix">
                <lottie-animation class="ico" :width="24" :height="24" path="lottie/discount.json" />
                {{ produit.final_price | formatNumberdecimal }}€
              </div>
              <div class="prix_reduc" >
                <span class="old">{{ produit.price | formatNumberdecimal }}€</span>
              </div>
            </div>
          </div>
        
          <div class="button_line" v-if="!$route.params.view && (produit.stock_suivi !== true || (produit.alert_stock > 0 || produit.alert_stock !== true))">
            <button :data-disabled="disabled_options" @click="panier_set">Ajouter {{ add_nb }} au panier <span class="price">{{ (add_nb * produit.final_price) + price_options | formatNumberdecimal }}€</span></button>
            <div class="numbers_set">
              <div @click="panier_remove" class="ico"><i class="el-icon-minus"></i></div>
              <div class="nb">{{ add_nb }}</div>
              <div @click="panier_add" class="ico"><i class="el-icon-plus"></i></div>
            </div>
          </div>
          <div class="rupture" v-else-if="!$route.params.view">En rupture de stock</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import anime from 'animejs'
import Vuex from "vuex";
// import _ from "lodash";
export default {
  name: "produit_show",
  props: [ 'id' ],
  data() {
    return {
      produit: {},
      // boutique: {},
      
      add_nb: 1,
      can_nb: 0,
      
      note: 4,
      
      load: false,
      
      sauce: null,
      
      options: [],
      // options_get: [],
      
      anim_end: false,
      
      options_select: {},
      price_options: 0,
      options_format: null,
      
      disabled_options: true
    }
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...Vuex.mapState({
      boutique: state => state.boutique.boutique,
      options_get: state => state.boutique.options,
    }),
  },
  created() {
    this.$load = false
    // this.$loader = true

  },
  watch: {
    boutique() {
      document.title = this.produit.name.replaceAll('_', ' ') + ' - ' + this.$store.state.boutique.boutique.name + ' - ' + document.title;
    },
    options_get() {
      // if(this.$load === false)
        this.options_init()
    }
  },
  mounted() {

    
    this.$http.get('/produit/' + this.id).then((response) => {
      this.$store.dispatch("boutique/sync", response.data.boutique_id);
      // this.$http.get('/boutique/' + response.data.boutique_id + '/no_produits').then((boutique) => {
      
      
        this.produit = response.data


        // 
      
        // this.boutique = this.$store.state.boutique.boutique
        // this.boutique = boutique.data
        if(this.options_get.length)  {
          this.options_init()
        }
        else {
          this.disabled_options = false
          this.anim_in()
        }
        
        this.$boutique_id = this.produit.boutique_id

        this.panier_calc()
        this.load = true
        // this.$http.get('/bo/boutique/' + response.data.boutique_id + '/bo_produits_options_all').then((response) => {
          // console.log(response.data)
          this.$loader = false
          // this.$forceUpdate()
          // this.produit.options_format = _.difference(response.data, this.produit.options)
        // })
      
      if(this.$store.state.boutique.boutique && this.produit.name)
        document.title = this.$store.state.boutique.boutique.name + ' : ' + this.produit.name + ' - ' + document.title;
      })
    // })

  },
  methods: {
    anim_in() {
      if(this.anim_end) return
      
      this.anim_end = true
      setTimeout(() => {

        document.querySelector('.produit_show .right .bloc_infos').style.opacity = 1
        anime({
          targets: '.bg', opacity: 1, easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', duration: 200, delay: 0
        })

        anime({
          targets: '.img img',
          scale: [1.1, 1],
          translateY: ['0px', 0],
          easing: 'cubicBezier(0.4, 0.0, 0.2, 1)',
          opacity: 1,
          delay: 100,
          duration: 300
        })
        anime({
          targets: '.right .bloc_infos',
          translateY: ['100px', 0],
          easing: 'cubicBezier(0.4, 0.0, 0.2, 1)',
          duration: 300,
          complete: () => {
            // document.querySelector('.produit_show').style.backgroundColor = "#fff"
          }
        })
        anime({
          targets: '.right .bloc_infos .line_drag', opacity: 1, easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', duration: 300, delay: 100
        })
        setTimeout(() => {
          anime({
            targets: '.right .bloc_infos .anim', translateX: ['-40px', 0], opacity: 1, easing: 'cubicBezier(0.4, 0.0, 0.2, 1)', duration: 300, delay: anime.stagger(20)
          })
        }, 200)
        setTimeout(() => {
          anime({
            targets: '.bloc_price',
            translateY: ['100px', 0],
            opacity: 1,
            easing: 'cubicBezier(0.4, 0.0, 0.2, 1)',
            duration: 300,
          })
        }, 200)


      }, 10)
    },
    options_init() {
      if(!this.options_get.length) return
      
      for(let n of this.options_get) {
        if(this.produit.options && this.produit.options.includes(n.id)) {
          if(!Array.isArray(n.conditions))
            n.conditions = n.conditions.split('_')
  
          if(!n.conditions[2])
            n.conditions_nb = 100

          if(!n.conditions[1])
            n.conditions_nb = 1

          this.options.push(n)
          this.options_select[n.id] = []

        }
      }
      
      let r = []
      for(let n in this.produit.options) {
        for(let o of this.options) {
          if(o.id === this.produit.options[n]) {
            r.push(o)
          }
        }
      }
      
      this.options = r
      
      this.options_check()
      this.anim_in()
    },
    options_check() {
      this.$forceUpdate()
      
      let obligatoire = []
      let check = []
      this.options_format = {}
      
      let by_key = {}
      
      this.price_options = 0
      
      for(let n of this.options) {
        if(n.conditions[0] && n.conditions[0] === 'obligatoire') {
          obligatoire.push(n.id)
        }
        by_key[n.id] = n
      }
      
      for(let n in this.options_select) {
        if(Object.keys(this.options_select[n]).length > 0 && by_key[n].conditions[2] === 'set' && Object.keys(this.options_select[n]).length >= by_key[n].conditions_nb)
          check.push(n)
        else if(Object.keys(this.options_select[n]).length > 0 && by_key[n].conditions[0] === 'obligatoire' && by_key[n].conditions[2] !== 'set') 
          check.push(n)
        
        
        if(!Array.isArray(this.options_select[n])) {
          this.options_format[n] = []
          this.options_format[n][0] = this.options_select[n]
          // this.options_format.push(this.options_select[n])
        }
        else {
          this.options_format[n] = this.options_select[n]
          // for(let d of this.options_select[n]) {
          //   this.options_format.push(d)
          // }
        }
      }
      
      
      for(let p in this.options_format) {
        for(let a of this.options_format[p]) {
          if (parseFloat(a.price) > 0) this.price_options += parseFloat(a.price)
        }
      }
      
      if(obligatoire.length === check.length)
        this.disabled_options = false
      else 
        this.disabled_options = true

    },
    panier_set() {
      if(this.disabled_options) {
        document.querySelector('.produit_show').scroll({
          top: 350,
          behavior: 'smooth'
        })
        return
      }
      
      if(this.produit.can_buy_nb && this.produit.can_buy_nb - this.can_nb === 0) {
        this.$message({ message: 'Tous les produits disponibles sont dans votre panier.', type: 'error'})
        return
      }
      
      let panier = localStorage.getItem("panier");
      panier = JSON.parse(panier)
      
      let p = {
        id: this.id,
        nb: this.add_nb,
        options: this.options_format
      }
      
      if(!panier[this.produit.boutique_id])
        panier[this.produit.boutique_id] = []
      
      
      panier[this.produit.boutique_id].push(p)
        
      // if(!panier[this.produit.boutique_id][this.id])
      //   panier[this.produit.boutique_id][this.id] = p
      // else
      //   panier[this.produit.boutique_id][this.id] += p
      
      localStorage.setItem('panier', JSON.stringify(panier));
      this.$router.go('-1')
    },
    panier_add() {
      if(this.add_nb < this.produit.can_buy_nb - this.can_nb && this.add_nb < 5)
        this.add_nb++
    },
    panier_remove() {
      if(this.add_nb > 1)
        this.add_nb--
    },
    panier_calc() {
      let panier = localStorage.getItem("panier");
      panier = JSON.parse(panier)
      if(!panier[this.produit.boutique_id]) {
        panier[this.produit.boutique_id] = []
        localStorage.setItem('panier', JSON.stringify(panier));
      }
      let p = panier[this.produit.boutique_id]
      this.$panier_indicator = 0

      
      if(panier[this.produit.boutique_id][this.id])
        this.can_nb = panier[this.produit.boutique_id][this.id]

      for(let n in p)
        this.$panier_indicator += p[n].nb
    }
  }
}
</script>

<style>


.produit_select .titre { height: 54px; display: flex; align-items: center; background: #f9f9f9; justify-content: space-between; font-size: 16px; margin-bottom: 10px; position: relative; 
  padding: 0 18px 0 24px; 
}
.produit_select .titre:before { content: ''; width: 40px; height: 2px; background: var(--gris); position: absolute; bottom: 0px }
.produit_select .titre img { width: 34px; }
.produit_select .titre .t { display: flex; flex-direction: column }
.produit_select .titre .t span { font-weight: 400; font-size: 14px; color: var(--black-sub) }
.produit_select .el-radio { display: flex; width: 100%; height: 45px; align-items: center; color: var(--black-main) }
.produit_select .el-radio[data-details] { height: 62px; align-items: flex-start }
.produit_select .el-radio[data-details] .l { padding-top: 2px; }
.produit_select .el-radio__inner { width: 17px; height: 17px; }
.produit_select .el-radio__inner::after { width: 6.5px; height: 6.5px; }
.produit_select .el-radio__label { display: flex; justify-content: space-between; width: 100%; text-transform: capitalize; font-size: 16px; position: relative; }

.produit_select .el-checkbox { display: flex; width: 100%; height: 45px; align-items: center; color: var(--black-main) }
.produit_select .el-checkbox[data-details] { height: 55px; align-items: flex-start }
.produit_select .el-checkbox[data-details] .l { padding-top: 2px; }
.produit_select .el-checkbox__inner { width: 17px; height: 17px; display: flex; position: relative; top: -1px }
.produit_select .el-checkbox__inner::after { border: none; background: #fff; width: 7px; height: 7px; left: 4px; top: 4px; transform: none!important; }
.produit_select .el-checkbox__label { display: flex; justify-content: space-between; width: 100%; text-transform: capitalize; font-size: 16px; position: relative; }


  .produit_show .el-rate__icon { font-size: 22px; margin-right: 0; }
  .produit_show .line .r { display: flex; align-items: center; position: relative; }
.produit_show .line .l { position: relative; display: flex; flex-direction: column; }
  .produit_show .line .l .details { color: var(--black-sub); font-weight: 400; font-size: 12px; line-height: 14px; padding-top: 2px; white-space: normal }
  .produit_show .line .recommande { font-size: 22px; position: absolute; top: -5px; right: -30px; color: var(--orange-button) }
  /*.produit_show .line .recommande { font-size: 12px; text-transform: capitalize; padding: 4px 8px 2px 8px; border-radius: 12px; background: var(--gris) }*/
  .produit_show .line { display: flex; align-items: center; padding: 0 24px;  }
  .produit_show .line .prix { font-weight: 400; margin-left: 6px; color: var(--black-sub) }
  /*.produit_show .el-rate__icon.el-icon-star-on { color: var(--orange-button) !important }*/


  @media only screen and (min-width: 800px) {

    .produit_select { margin-left: 24px; }
    .produit_select .titre { border-radius: 6px; }
    .produit_select .el-radio[data-details] { height: 55px; }
  }
</style>

<style scoped>






.produit_show { position: fixed; top: 0; right: 0; left: 0; bottom: 0; background: #fff; overflow: auto; z-index: 100; font-family: "TT Interfaces"; font-weight: 600; }
  /*.produits .produit:nth-child(odd) { mr }*/
  .produit_show .close { position: fixed; left: 20px; top: 20px; z-index: 5; background: var(--blanc-button); fill: var(--black-sub); width: 40px; height: 40px; border-radius: 6px; cursor: pointer }
  .produit_show .bg { background: #e9e9e9; position: absolute; top: 0; right: 0; height: 60vh; left: 0; opacity: 0; }
  .produit_show .img { width: 100%; height: 0; padding-bottom: 100%; overflow: hidden;
    position: fixed; top: 0; left: 0; top: 0
  }
  .produit_show .img img { width: 100%; background: #e9e9e9; position: relative; z-index: 2;  opacity: 0; }
  .produit_show .line_price { height: 60px; display: flex; align-items: center; padding: 0 20px; z-index: 3 }
  .produit_show .line_price .prix { font-weight: bold; font-size: 20px }
  .produit_show .title { min-height: 40px; font-size: 34px; line-height: 38px; margin-top: 20px; color: var(--black-main)}
  .produit_show .description { margin-bottom: 20px; font-size: 16px; color: var(--black-sub); line-height: 24px; font-weight: 400; margin-top: 16px; white-space: pre-line}
  .produit_show .right .bloc_infos { padding: 4px 0 110px 0; margin-bottom: 100px; border-radius: 30px; z-index: 2; background: #fff;
    position: absolute; top: 91vw; left: 0; right: 0; transform: translateY(-100px); opacity: 0;
  }
  .produit_show .pad { padding: 0 24px 0 24px; }
  .produit_show button { width: 100%; background: var(--vert-button); font-size: 16px; border-radius: 24px }
  .produit_show button[disabled] { background: var(--gris-button) }
  .produit_show .line_drag { background: var(--gris); height: 5px; width: 100px; border-radius: 4px; margin: 14px auto 10px auto; opacity: 0; }
  
  .rupture { padding: 0; height: 50px; background: #eee; font-size: 16px; display: flex; align-items: center; justify-content: center; }
  
  .bloc_price { position: fixed; bottom: 0; left: 0; right: 0; padding: 20px 24px; background: #fff; box-shadow: 0 -4px 50px rgba(204,204,204,.5); z-index: 5; border-radius: 30px 30px 0 0 ; opacity: 0;}

  .produit_show .button_line { display: flex; flex-direction: row-reverse; align-items: center; }
  
  .numbers_set { display: flex; align-items: center; justify-content: center; margin-right: 16px; }
  .numbers_set .ico { width: 48px; height: 48px; display: flex; align-items: center; justify-content: center; background: var(--gris); border-radius: 100%; cursor: pointer }
  .numbers_set .nb { width: 42px; text-align: center; font-family: "Prompt"; }
  
  .note_line { display: flex; align-items: center; font-size: 14px; margin-top: 6px; margin-bottom: 12px; color: var(--black-sub); font-weight: 400; }
  .note_line span { position: relative; top: 2px; margin-left: 6px; }


  .discount { display: flex; align-items: center; justify-content: space-between; margin-bottom: 12px; }
  .discount .prix { font-weight: bold; font-size: 20px; color: #000; display: flex; align-items: center }
  
  .discount .percent { background: #f44336; color: #fff; font-size: 26px; font-weight: 600; padding: 3px 6px }
  .discount .price { display: flex; flex-direction: column; align-items: flex-end }
  
  .discount .prix { color: #f44336; }
  .discount .prix .ico { margin-left: 0!important; margin-right: 4px!important; position: relative; top: -1px }
  .discount .prix_reduc { font-weight: 400; font-size: 16px; color: #C92C2C; margin-left: 8px; }
  .discount .prix_reduc .old { text-decoration: line-through; color: var(--boColor-gris3) }
  
  


  @media only screen and (min-width: 800px) {
    .bg { display: none }
    .produit_show { top: 69px; }
    .produit_show .button_line { flex-direction: column }
    .produit_show .close { position: absolute; }

    .numbers_set { margin-top: 16px; margin-right: 0; }

    .numbers_set .ico { background: #fff }

    .produit_show .grp_page { display: flex; flex-direction: row; min-height: 100%; width: calc(100% - 74px); min-width: 800px; margin-left: 74px; }
    
    .produit_show .img { width: 20vw; padding-bottom: 20vw; margin: 0; padding-top: 64px; position: relative; }
    .produit_show .img img { border-radius: 30px }
    
    .produit_show .right { width: calc(100% - 20vw); display: flex; top: 0; margin-top: 48px; position: relative; }
    .produit_show .right .bloc_infos { position: relative; top: 0; padding: 0; margin: 0; }
    .produit_show .right .line_drag { display: none }
    .produit_show .right .bloc_infos { padding: 0 60px; margin-top: 54px; width: calc(100% - 300px); }
    .produit_show .right .bloc_infos .title { margin-top: 0; }
    .produit_show .right .bloc_infos .description { font-size: 1.1vw; line-height: 1.66vw }
    .produit_show .right .bloc_price { position: relative; width: 300px; flex: 0 0 auto; height: 160px; margin-top: 34px; padding: 24px 24px; background: #f5f5f5; border-radius: 30px;
      box-shadow: none; margin-right: 24px;
    }

    /*.produit_show .right { display: flex; }*/
    /*.produit_show .right { display: flex; }*/
    /*.bottom { position: static; padding: 0; border-top: 0; min-width: 200px; }*/
  }
  @media only screen and (min-width: 1200px) {
    .produit_show .img { width: 35vw; padding-bottom: 35vw;}
    .produit_show .right { width: calc(100% - 35vw); }
    /*.produit_show { padding: 30px; display: flex; align-items: center; justify-content: center; }*/
    /*.produit_show .grp_page { display: flex; width: 100%; justify-content: space-between; }*/
    /*.produit_show .img { width: 30%; padding-bottom: 30%; margin: 0 }*/
    /*.produit_show .title { margin-top: 0; }*/
    
    /*.produit_show .right { width: 70%; padding-left: 80px; position: relative; height: calc(30vw - 80px); }*/

  }
  
</style>