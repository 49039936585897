<template>
  <produit_show :id="$route.params.product_id"></produit_show>
</template>

<script>
import produit_show from "@/components/produit/produit_show";
export default {
  name: "produit",
  components: { produit_show }
}
</script>

<style scoped>

</style>